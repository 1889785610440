<template>
    <!-- 视频 -->
    <div class="video-body-bg">

        <van-nav-bar
        title="综合"
      />
        <div class="sidebar-box" :class="{ sidebarLeft: isActive}" >
            <van-sidebar v-model="activeKey"  @change="onChange">
                <van-sidebar-item title="教学视频" />
                <!-- <van-sidebar-item title="开箱视频" /> -->
                <!-- <van-sidebar-item title="RTK评测" /> -->
                <van-sidebar-item title="鉴定证书" />
                <van-sidebar-item title="授权证书" />
                <van-sidebar-item title="获奖证书" />
            </van-sidebar>
        </div>

         <div class="right-list" :class="{ listLeft: isActive}" style="-webkit-overflow-scrolling: touch;"><router-view /></div>
         <div v-show="showBtn" class="show-btn iconfont icon-arrow-right-bold" @click="showSidebar()"></div>
    </div>
</template>
<script>
// import rightList from '@/views/video/child/rightList.vue';
export default {
  data() {
    return {
      activeKey: 0,
      showBtn:false,//隐藏显示按钮
      // sidebarLeft:0, //
      // listLeft:"86px", //
      timer:null,//定时器
      isActive:false,
      value:""
    };
  },
  created: function() {
  },
  methods: {
    onChange(index) {
      //有rtk评测的时候
      // if(index!=1){
      //   clearInterval(this.timer);
      //   this.timer=null;
      // }
      // if(index==0){
      //   this.$router.push({name:'course'})
      // }else if(index==1) {
      //   this.$router.push({name:'rtkPage'})
      //   this.showBtn=true;
      //   this.isActive=true;
      // } else if(index==2) {
      //   this.$router.push({name:'certificate'})
      // } else if(index==3) {
      //   this.$router.push({name:'accredit'})
      // }else if(index==4) {
      //   this.$router.push({name:'award'})
      // }
      //取消RTK评测网
      if(index==0){
        this.$router.push({name:'course'})
      } else if(index==1) {
        this.$router.push({name:'certificate'})
      } else if(index==2) {
        this.$router.push({name:'accredit'})
      }else if(index==3) {
        this.$router.push({name:'award'})
      }
    },
    showSidebar() {
      let _this=this;
      this.showBtn=false;
      this.isActive=false;
      this.timer=setTimeout(function(){
            _this.showBtn=true;
            _this.isActive=true;
      },3000)
    }
  }
};
</script>
<style lang="less" scoped>
    .video-body-bg {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #F7F4F8;
        bottom: 102px;
        overflow: hidden;
    }
    .van-sidebar {
        width: 170px;
    }
    .sidebar-box {
        position: absolute;
        top: 120px;
        bottom: 0;
        left: 0;
        background: #fff;
    }
    .sidebarLeft {
      left: -170px;
    }
    .show-btn {
      position: absolute;
      top: 50%;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #666;
      background: rgba(192, 156, 123);
    }
    .van-sidebar-item {
        background: #fff;
    }
    .van-sidebar-item--select::before {
        background-color: #FE8A4F;
    }
    .van-sidebar-item--select  {
        color: #FE8A4F;
    }
    .right-list {
        position: absolute;
        top: 120px;
        bottom: 0px;
        left: 170px;
        right: 0;
        overflow-y: scroll;
    }
    .listLeft {
        left: 0;
    }


</style>
